export const SIGN_IN_ROUTE = '/';
export const MAIN_ROUTE = '/main';
export const DEVICES_ROUTE = '/device';
export const DEVICES_INFO_ROUTE = '/info';
export const DEVICES_VALUE_ROUTE = '/measure';
export const DEVICES_ARCHIVE_ROUTE = '/archive';
export const DEVICES_DIF_ROUTE = '/diff';
export const DEVICES_CHART_ROUTE = '/chart';
export const DEVICES_SETUP_ROUTE = '/setup';
export const SERVICE_ROUTE = '/service';
export const LOG_ROUTE = '/logs';
export const GROUP_TASK_ROUTE = '/group-task';
export const ONLINE_ROUTE = '/online';
export const EVENT_ROUTE = '/events';
