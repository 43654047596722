import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataGrid,
  GridColDef,
  GridColumnGroupingModel,
  ruRU,
} from '@mui/x-data-grid';
import { Box, Card, createTheme, Stack, ThemeProvider } from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  replaceQuoat,
  roundValue,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  DIFF_PROC,
  INTERVAL_UPDATE,
  LANGUAGE_RUS,
  MOBILE_VIEW,
  PAGE_SIZE_DEVICES,
  ROUND_VOLUME,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DEVICES_INFO_ROUTE } from '../../constant/routes';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetOnline } from '../../service/online/apiGetOnline';

export const OnlineDiv = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);

  const getOnline = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetOnline();
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.online);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getOnline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [intervalLeft, setIntervalLeft] = useState(0);
  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setIntervalLeft(intervalLeft + 1);
      if (intervalLeft >= INTERVAL_UPDATE) {
        if (isReady) {
          getOnline();
        }
        setIntervalLeft(0);
      }
    }, 1000);
    // clearing interval
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const columns: GridColDef[] = [
    {
      field: 'holder',
      headerName: t('headerHolder'),
      renderHeader: () => <strong>{t('headerHolder')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <a
            href={`${DEVICES_INFO_ROUTE}/${row.row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {replaceQuoat(row.row.holder, 0)}
          </a>
        </Stack>
      ),
    },
    {
      field: 'regionName',
      headerName: t('headerRegion'),
      renderHeader: () => <strong>{t('headerRegion')}</strong>,
      minWidth: 190,
      editable: false,
      renderCell: (row) => row.row.regionName,
    },
    {
      field: 'volToday',
      headerName: t('headerGroupVolumeAgnks'),
      renderHeader: () => <strong>{t('headerGroupVolumeAgnks')}</strong>,
      minWidth: 120,
      editable: false,
      renderCell: (row) => roundValue(row.row.volToday, ROUND_VOLUME),
    },
    {
      field: 'volVuToday',
      headerName: t('headerGroupVolumeVu'),
      renderHeader: () => <strong>{t('headerGroupVolumeVu')}</strong>,
      minWidth: 120,
      editable: false,
      renderCell: (row) => roundValue(row.row.volVuToday, ROUND_VOLUME),
    },
    {
      field: 'volDiffToday',
      headerName: t('headerGroupDiffAbs'),
      renderHeader: () => <strong>{t('headerGroupDiffAbs')}</strong>,
      minWidth: 100,
      editable: false,
      renderCell: (row) => roundValue(row.row.volDiffToday, ROUND_VOLUME),
    },
    {
      field: 'volDiffTodayProc',
      headerName: t('headerGroupDiffProc'),
      renderHeader: () => <strong>{t('headerGroupDiffProc')}</strong>,
      minWidth: 50,
      editable: false,
      renderCell: (row) => (
        <div
          style={{
            color:
              parseFloat(row.row.volDiffTodayProc) > DIFF_PROC
                ? '#ef5350'
                : '#009688',
          }}
        >
          {roundValue(row.row.volDiffTodayProc, ROUND_VOLUME)}
        </div>
      ),
    },
    {
      field: 'volLastday',
      headerName: t('headerGroupVolumeAgnks'),
      renderHeader: () => <strong>{t('headerGroupVolumeAgnks')}</strong>,
      minWidth: 120,
      editable: false,
      renderCell: (row) => roundValue(row.row.volLastday, ROUND_VOLUME),
    },
    {
      field: 'volVuLastday',
      headerName: t('headerGroupVolumeVu'),
      renderHeader: () => <strong>{t('headerGroupVolumeVu')}</strong>,
      minWidth: 120,
      editable: false,
      renderCell: (row) => roundValue(row.row.volVuLastday, ROUND_VOLUME),
    },
    {
      field: 'volDiffLastday',
      headerName: t('headerGroupDiffAbs'),
      renderHeader: () => <strong>{t('headerGroupDiffAbs')}</strong>,
      minWidth: 100,
      editable: false,
      renderCell: (row) => roundValue(row.row.volDiffLastday, ROUND_VOLUME),
    },
    {
      field: 'volDiffLastdayProc',
      headerName: t('headerGroupDiffProc'),
      renderHeader: () => <strong>{t('headerGroupDiffProc')}</strong>,
      minWidth: 50,
      editable: false,
      renderCell: (row) => (
        <div
          style={{
            color:
              parseFloat(row.row.volDiffTodayProc) > DIFF_PROC
                ? '#ef5350'
                : '#009688',
          }}
        >
          {roundValue(row.row.volDiffLastdayProc, ROUND_VOLUME)}
        </div>
      ),
    },
    {
      field: 'dateConnect',
      headerName: t('headerConnect'),
      renderHeader: () => <strong>{t('headerConnect')}</strong>,
      minWidth: 170,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => (
        <div style={{ color: row.row.isConnect === 1 ? '#ef5350' : '#009688' }}>
          {unixToLocal(row.row.dateConnect)}
        </div>
      ),
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'groupInfo',
      renderHeaderGroup: () => <strong>{t('headerGroupInfo')}</strong>,
      children: [
        {
          field: 'holder',
        },
        {
          field: 'regionName',
        },
      ],
    },
    {
      groupId: 'groupToday',
      renderHeaderGroup: () => <strong>{t('headerGroupNowDay')}</strong>,
      children: [
        {
          groupId: 'groupTodayVol',
          renderHeaderGroup: () => <strong>{t('headerGroupVolume')}</strong>,
          children: [
            {
              field: 'volToday',
            },
            {
              field: 'volVuToday',
            },
          ],
        },
        {
          groupId: 'groupTodayDif',
          renderHeaderGroup: () => <strong>{t('headerGroupDiff')}</strong>,
          children: [
            {
              field: 'volDiffToday',
            },
            {
              field: 'volDiffTodayProc',
            },
          ],
        },
      ],
    },
    {
      groupId: 'groupLastDay',
      renderHeaderGroup: () => <strong>{t('headerGroupLastDay')}</strong>,
      children: [
        {
          groupId: 'groupLastDayVol',
          renderHeaderGroup: () => <strong>{t('headerGroupVolume')}</strong>,
          children: [
            {
              field: 'volLastday',
            },
            {
              field: 'volVuLastday',
            },
          ],
        },
        {
          groupId: 'groupLastDayDif',
          renderHeaderGroup: () => <strong>{t('headerGroupDiff')}</strong>,
          children: [
            {
              field: 'volDiffLastday',
            },
            {
              field: 'volDiffLastdayProc',
            },
          ],
        },
      ],
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        marginBottom="20px"
        spacing={2}
      >
        <Stack width="90%">
          <BodyHeaderDiv title={t('tooltipOnline')} timeLeft={intervalLeft} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Card
            style={{ padding: '20px', height: '100%', background: '#f5f5f5' }}
          >
            <Box
              height="100%"
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    background: '#f5f5f5',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  headerHeight={35}
                  columns={columns}
                  rows={deviceDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_DEVICES}
                  disableSelectionOnClick
                  experimentalFeatures={{
                    newEditingApi: true,
                    columnGrouping: true,
                  }}
                  columnGroupingModel={columnGroupingModel}
                />
              </ThemeProvider>
            </Box>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
