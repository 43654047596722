import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';
import { OnlineDiv } from '../component/online/OnlineDiv';

export const OnlinePage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<OnlineDiv />}
  />
);
