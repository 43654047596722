import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import EventIcon from '@mui/icons-material/Event';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import BoltIcon from '@mui/icons-material/Bolt';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import { MainDivGridItem } from './MainDivGridItem';
import { MainStatDto } from '../../dto/MainStatDto';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetStats } from '../../service/device/apiGetStats';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyAutoComplete } from '../UI/MuAutoComplete';
import { MAIN_COLOR_STRING, REGION_NAME } from '../../constant/constants';
import { RegionEnum } from '../../enum/RegionEnum';
import { ONLINE_ROUTE } from '../../constant/routes';

export const MainDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [mainStatsDto, setMainStats] = useState<MainStatDto>();

  const [selectRegion, setSelectRegion] = useState(
    parseInt(localStorage.getItem(REGION_NAME) ?? '-1', 10)
  );

  const saveRegion = (value: any) => {
    setSelectRegion(Object.values(RegionEnum).indexOf(value));
    localStorage.setItem(
      REGION_NAME,
      Object.values(RegionEnum).indexOf(value).toString()
    );
  };

  const getStats = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetStats();
    if (apiResult.isSuccess) {
      setMainStats(apiResult.data.stats);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('dashboard')} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <MyAutoComplete
                width="400px"
                value={
                  selectRegion !== undefined && selectRegion !== -1
                    ? RegionEnum[selectRegion]
                    : ''
                }
                onChange={(e, v) => saveRegion(v)}
                arrayList={Object.values(RegionEnum).filter(
                  (value) => typeof value === 'string'
                )}
                size="small"
                label={t('headerRegion')}
              />
              <Stack>
                <IconButton
                  title={t('tooltipApplyRegion')}
                  size="large"
                  onClick={() => getStats()}
                >
                  <FindReplaceIcon
                    fontSize="large"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack>
              <IconButton
                title={t('tooltipOnline')}
                size="large"
                onClick={() => navigate(ONLINE_ROUTE)}
              >
                <BoltIcon fontSize="large" sx={{ color: '#ff5722' }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack width="90%" spacing={1}>
          <Grid container spacing={1}>
            <MainDivGridItem
              image={AppRegistrationIcon}
              color="#ff9800"
              param={DeviceStageEnum.UNREG}
              value={mainStatsDto?.cntUnReg}
            />
            <MainDivGridItem
              image={LocalGasStationIcon}
              color="#03a9f4"
              param={DeviceStageEnum.REG}
              value={mainStatsDto?.cntReg}
            />
            <MainDivGridItem
              image={LinkOffIcon}
              color="#f44336"
              param={DeviceStageEnum.CONNECT}
              value={mainStatsDto?.cntConnect}
            />
            <MainDivGridItem
              image={ReportProblemIcon}
              color="#ff5722"
              param={DeviceStageEnum.LIMIT}
              value={mainStatsDto?.cntLimit}
            />
            <MainDivGridItem
              image={EventIcon}
              color="#9c27b0"
              param={DeviceStageEnum.SERTIFICATE}
              value={mainStatsDto?.cntSert}
            />
          </Grid>
        </Stack>
      </Stack>
    </div>
  );
};
