import {
  JSON_CONTENT_TYPE,
  REGION_NAME,
  TOKEN_NAME,
} from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetDevices = async (state: string, search?: string) => {
  const params: IApiParams = {
    url:
      search === undefined
        ? `/device/getDevices.php?state=${state}`
        : `/device/getDevices.php?state=${state}&search=${search}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
      'Api-Region': localStorage.getItem(REGION_NAME),
    },
  };
  return getApi(params, 'GET');
};
