export enum RegionEnum {
  'Не выбран',
  'Ташкентская',
  'Сырдарьинская',
  'Джизакская',
  'Самаркандская',
  'Ферганская',
  'Андижанская',
  'Наманганская',
  'Наваинская',
  'Бухарская',
  'Кашкадарьинская',
  'Сурхандарьинская',
  'Харезмская',
  'Ташкент Город',
  'Каракалпакстан',
  'Все регионы',
}
