import React, { useState } from 'react';
import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { LANGUAGE_RUS, LANGUAGE_UZ } from '../../constant/constants';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#009688',
    },
  },
});

export const SignNavigationBar = () => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const shouldOpenMenu = Boolean(anchorMenu);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };
  const { i18n } = useTranslation();
  const selectLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    handleCloseMenu();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={0}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'revert',
                    fontWeight: 750,
                    fontSize: '28px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  TM-
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 100,
                    fontSize: '28px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  AGNKS
                </Typography>
              </Stack>
              {false && (
                <Stack>
                  <IconButton
                    id="basic-button"
                    aria-controls={shouldOpenMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={shouldOpenMenu ? 'true' : undefined}
                    onClick={handleClickMenu}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <LanguageIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorMenu}
                    open={shouldOpenMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => selectLanguage(LANGUAGE_UZ)}>
                      UZ
                    </MenuItem>
                    <MenuItem onClick={() => selectLanguage(LANGUAGE_RUS)}>
                      RU
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
