import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  formatDateArch,
  getDefaultAlertProps,
  getDefaultDateToReport,
  getSelectedLng,
  getToolTipColoncaDescr,
  otherToDataGrid,
  replaceQuoat,
  roundValue,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  EXCEL_ARCHIVE,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_ARCHIVES,
  PDF_ARCHIVE,
  ROUND_VOLUME,
  START_PAGE_SIZE_ARCHIVES,
  VIEW_ARCHIVE,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DeviceArchiveDto } from '../../dto/DeviceArchiveDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetArchive } from '../../service/archive/apiGetArchive';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyDateTextField } from '../UI/MyDateTextField';
import { DeviceInfoDto } from '../../dto/DeviceInfoDto';

export const DeviceArchiveDiv = () => {
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [dateTimeArch, setDateTimeArch] = useState(
    getDefaultDateToReport(0, 'month')
  );
  const [deviceNum, setDeviceNum] = useState('');
  const [archiveDtos, setArchiveDtos] = useState<DeviceArchiveDto[]>([]);
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_ARCHIVES);
  const [deviceInfoDto, setDeviceDto] = useState<DeviceInfoDto>();

  const getArch = async (
    idDevice: string,
    print: string,
    datetime?: string,
    name?: string
  ) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetArchive(
      idDevice,
      print,
      datetime
    );
    if (apiResult.isSuccess) {
      if (print === VIEW_ARCHIVE) {
        setArchiveDtos(apiResult.data.archive);
        document.title =
          apiResult.data.info.holder !== ''
            ? `${replaceQuoat(apiResult.data.info.holder, 0)}`
            : `${apiResult.data.info.imei}`;
        setDeviceDto(apiResult.data.info);
        setDeviceNum(apiResult.data.info.deviceNum);
      } else {
        FileDownload(apiResult.data, `Архив_${name}` ?? '');
      }
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleViewArchive = async () => {
    await getArch(id ?? '', VIEW_ARCHIVE, formatDateArch(dateTimeArch));
  };

  const handlePdfArchive = async () => {
    await getArch(
      id ?? '',
      PDF_ARCHIVE,
      formatDateArch(dateTimeArch),
      `${deviceInfoDto?.holder}_${formatDateArch(dateTimeArch)}.pdf`
    );
  };

  const handleExcelArchive = async () => {
    await getArch(
      id ?? '',
      EXCEL_ARCHIVE,
      formatDateArch(dateTimeArch),
      `${deviceInfoDto?.holder}_${formatDateArch(dateTimeArch)}.xlsx`
    );
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columnsArchive: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('headerArchDatetime'),
      renderHeader: () => <strong>{t('headerArchDatetime')}</strong>,
      minWidth: 250,
      sortable: false,
      editable: false,
      renderCell: (row) => row.row.datetime,
    },
    {
      field: 'azsVolume',
      headerName: t('headerArchVol'),
      renderHeader: () => <strong>{t('headerArchVol')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => roundValue(row.row.azsVolume, ROUND_VOLUME),
    },
    {
      field: 'vuVolume',
      headerName: t('headerArchVolVu'),
      renderHeader: () => <strong>{t('headerArchVolVu')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => roundValue(row.row.vuVolume, ROUND_VOLUME),
    },
    {
      field: 'diff',
      headerName: t('headerArchVolDiff'),
      renderHeader: () => <strong>{t('headerArchVolDiff')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => roundValue(row.row.diff, ROUND_VOLUME),
    },
    {
      field: 'diffProc',
      headerName: t('headerArchVolDiffProc'),
      renderHeader: () => <strong>{t('headerArchVolDiffProc')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => roundValue(row.row.diffProc, ROUND_VOLUME),
    },
  ];

  const [period, setPeriod] = useState('month');

  const handleChangePeriod = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPeriod((event.target as HTMLInputElement).value);
    setDateTimeArch(
      getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
    );
    await getArch(
      id ?? '',
      VIEW_ARCHIVE,
      formatDateArch(
        getDefaultDateToReport(0, (event.target as HTMLInputElement).value)
      )
    );
  };

  useEffect(() => {
    getArch(id ?? '', VIEW_ARCHIVE, formatDateArch(dateTimeArch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('report')}
            isFlowmeter
            deviceInfo={
              deviceInfoDto && `${replaceQuoat(deviceInfoDto?.holder, 0)}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Card style={{ padding: '20px', background: '#f5f5f5' }}>
            <Stack direction="column" spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing={2}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t('reportPeriod')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="month"
                      onChange={handleChangePeriod}
                    >
                      <FormControlLabel
                        value="month"
                        control={<Radio />}
                        label={t('month')}
                      />
                      <FormControlLabel
                        value="number"
                        control={<Radio />}
                        label={t('year')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <MyDateTextField
                    type={period}
                    value={dateTimeArch}
                    onChange={(e) => setDateTimeArch(e.target.value)}
                    width="200px"
                    label={t('date')}
                    size="small"
                  />
                  <IconButton
                    size="medium"
                    onClick={handleViewArchive}
                    title={t('tooltipLook')}
                    sx={{ color: MAIN_COLOR_STRING }}
                  >
                    <FindInPageIcon fontSize="large" />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="medium"
                    onClick={handlePdfArchive}
                    title={t('tooltipPdf')}
                    sx={{ color: '#f44336' }}
                  >
                    <PictureAsPdfIcon fontSize="large" />
                  </IconButton>
                  {false && (
                    <IconButton
                      size="medium"
                      onClick={handleExcelArchive}
                      title={t('tooltipExcel')}
                      sx={{ color: '#4caf50' }}
                    >
                      <ArticleIcon fontSize="large" />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              <Box
                sx={{
                  width: '100%',
                  '& .stage-green': {
                    backgroundColor: 'green',
                  },
                  '& .stage-orange': {
                    backgroundColor: 'orange',
                  },
                  '& .stage-red': {
                    backgroundColor: '#ef5350',
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    sx={{
                      background: '#f5f5f5',
                      '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                        fontSize: '100px',
                      },
                      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '5px',
                      },
                      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                          py: '5px',
                        },
                      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                          outline: 'none !important',
                        },
                    }}
                    getRowHeight={() => 'auto'}
                    columns={columnsArchive}
                    rows={archiveDtos}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...otherToDataGrid}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={PAGE_SIZE_ARCHIVES}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                  />
                </ThemeProvider>
              </Box>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </div>
  );
};
