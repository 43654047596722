import {
  JSON_CONTENT_TYPE,
  REGION_NAME,
  TOKEN_NAME,
} from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { getApi } from '../getApi';

export const apiGetOnline = async () => {
  const params: IApiParams = {
    url: `/online/getOnline.php`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      Token: localStorage.getItem(TOKEN_NAME),
      'Api-Region': localStorage.getItem(REGION_NAME),
    },
  };
  return getApi(params, 'GET');
};
