import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnArr } from '../../dto/DeviceInfoDto';
import { roundValue } from '../../utils/utils';
import {
  ROUND_DENS,
  ROUND_PRES,
  ROUND_PRICE,
  ROUND_VOLUME,
} from '../../constant/constants';

interface Props {
  dto?: ColumnArr;
}

export const DeviceValueColoncaItem: FunctionComponent<Props> = ({ dto }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={2}>
      <Card style={{ padding: '10px', background: '#ffab91' }}>
        <Stack direction="column" spacing={1}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {t('headerColonca')} {dto?.name}
          </Typography>
          <Divider />
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {roundValue(dto?.vol, ROUND_VOLUME)} {t('unitVolume')}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {roundValue(dto?.pres, ROUND_PRES)} {t('unitPres')}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {roundValue(dto?.dens, ROUND_DENS)} {t('unitDens')}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {roundValue(dto?.price, ROUND_PRICE)} {t('unitPrice')}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};

DeviceValueColoncaItem.defaultProps = {
  dto: undefined,
};
